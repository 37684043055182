export const TERMINAL_COMMANDS = Object.freeze({
  RESET_CORE: 2,
  BLOCK: 5,
  UNBLOCK: 6,
  REQUEST_STATUSES: 11,
  REFRESH: 7,
})

export const DEVICE_COMMANDS = Object.freeze({
  BLOCK: 1,
  UNBLOCK: 2,
  REFRESH: 3,
})
