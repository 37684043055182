<template>
  <div class="terminal-wrapper my-2">
    <div class="terminal">
      <header>
        <div class="header-top">
          <div>T {{ terminal.id }}</div>
          <div>
            <span>{{ terminal.state.status.last_packet_time | getShortTime }}</span>
            <sup class="ml-1">{{ terminal.state.status.last_packet_time | getShortDate }}</sup>
          </div>
        </div>
        <div class="header-bottom">
          <v-icon
            @click="sendCommand('REQUEST_STATUSES')"
            :color="terminal.state.status.is_online ? terminal.state.status.cb_colour : 'grey'"
          >
            mdi-refresh
          </v-icon>
        </div>
      </header>
      <main class="text-sm-center">
        <OnlineTerminalSVG :fillColor="fillColor" :posColor="posColor" />
        <div class="text-truncate">
          <img v-if="terminal.state.status.is_blocked" :src="require('@/assets/images/lock.svg')" height="13" alt="lock" />
          <span class="ml-1">{{ terminal.state.status.cb_status }}</span>
        </div>
      </main>
      <footer class="footer">
        <div class="footer-top">
          <div>{{ terminal.state.info.modem_available_traffic }} GB</div>
          <div class="d-flex align-center">
            <span class="mr-1">{{ terminal.state.balance.bills_count }}</span>
            <img :src="require('@/assets/images/cash_2.svg')" alt="cash" />
          </div>
        </div>
        <div class="footer-bottom">
          <div></div>
          <div>
            <span v-show="terminal.state.balance.bill_acceptor_load">{{ terminal.state.balance.bill_acceptor_load }}%</span>
            <MoneySVG :fill-color="terminal.state.status.ba_colour" />
          </div>
        </div>
      </footer>
    </div>
    <template v-if="terminal.devices.length">
      <v-slide-group class="my-2" show-arrows>
        <v-slide-item v-for="device in terminal.devices" :key="device.id">
          <div class="ma-2">
            <OnlineDeviceWM v-if="device.type === 1" :terminal="terminal" :device="device" />
            <OnlineDeviceAM v-else-if="device.type === 9" :terminal="terminal" :device="device" />
          </div>
        </v-slide-item>
      </v-slide-group>
    </template>
    <!--    <div v-if="terminal.devices.length" class="d-flex overflow-x-auto">-->
    <!--      <div v-for="device in terminal.devices" :key="device.id" class="ma-2">-->
    <!--        <OnlineDeviceWM :terminal="terminal" :device="device" />-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
</template>

<script>
import convertDate from '@/mixins/convertDate'
import { TERMINAL_COMMANDS } from '@/const/commands'
import { mapActions } from 'vuex'

export default {
  name: 'OnlineTerminal',

  components: {
    OnlineDeviceWM: () => import('@/components/common/online/OnlineDeviceWM'),
    OnlineDeviceAM: () => import('@/components/common/online/OnlineDeviceAM'),
    OnlineTerminalSVG: () => import('@/components/icons/OnlineTerminalSVG'),
    MoneySVG: () => import('@/components/icons/MoneySVG'),
  },

  mixins: [convertDate],

  props: {
    terminal: {
      type: Object,
      required: true,
      default: () => {},
    },
  },

  data: () => ({}),

  computed: {
    currentCompanyId() {
      return +this.$route.params.id
    },
    fillColor() {
      if (this.terminal.state.status.is_online) {
        return this.terminal.state.status.cb_colour || 'grey'
      } else {
        return 'grey'
      }
    },
    posColor() {
      if (this.terminal.state.status.is_online) {
        return this.terminal.state.status.pos_colour || 'grey'
      } else {
        return 'grey'
      }
    },
  },

  created() {},

  beforeDestroy() {},

  methods: {
    ...mapActions('monitoring', ['sendMonitoringTerminalCommand']),

    async sendCommand(command) {
      const payload = {
        companyId: this.currentCompanyId,
        terminalId: this.terminal.id,
        directiveTypeId: TERMINAL_COMMANDS[command],
      }
      await this.sendMonitoringTerminalCommand(payload)
    },
  },
}
</script>

<style scoped lang="scss">
.terminal {
  &-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  width: 160px;
  height: 200px;
  border-radius: 8px;
  box-shadow: 0 1px 3px 1px rgba(0, 41, 143, 0.15);
  padding: 0 8px;
}
.header {
  &-top {
    display: flex;
    justify-content: space-between;
    div:first-child {
      font-size: 10px;
      font-weight: 700;
      line-height: 28px;
    }
    div:last-child {
      height: 28px;
      border-radius: 4px;
      padding: 8px 4px;
      background-color: rgba(246, 245, 245, 1);
      font-size: 10px;
      color: rgba(0, 132, 61, 1);
    }
  }
}
main {
  margin-top: 5px;
  & > div:last-child {
    font-size: 12px;
  }
}
.footer {
  margin-top: 12px;
  &-top {
    display: flex;
    justify-content: space-between;
    font-size: 10px;
  }
  &-bottom {
    display: flex;
    justify-content: space-between;
    span {
      margin-left: 4px;
      font-size: 10px;
    }
  }
}
</style>
